import { PostmessageExternalSender } from '@3ps/postmessage-api-external';

class PostMessageSenderService {
  private sender = new PostmessageExternalSender();

  public keepAlive = () => {
    this.sender.keepAlive();
  };

  public close = () => {
    this.sender.close();
  };

  public openUrl = (url: string) => {
    this.sender.openUrl(url);
  };

  public pageLoaded = () => {
    this.sender.pageLoaded();
  };

  public pageReady = () => {
    this.sender.pageReady();
  };

  public scan = () => {
    this.sender.scanBarcode();
  };

  public closeWebview = (viewName: string) => {
    this.sender.closeWebview(viewName);
  };

  public refresh = () => {
    this.sender.refresh('customerCards');
  };
}

export default new PostMessageSenderService();
