import React from 'react';
import { BackHandler } from './components/BackHandler/BackHandler';
import { KeepAliveHandler } from './components/KeepAliveHandler/KeepAliveHandler';
import { KBCAuthCodeHandler } from './components/KBCAuthCodeHandler/KBCAuthCodeHandler';

export class PostMessageProvider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <BackHandler />
        <KBCAuthCodeHandler />
        <KeepAliveHandler>{this.props.children}</KeepAliveHandler>
      </React.Fragment>
    );
  }
}
