export enum PostMessageType {
  NAVIGATE_TO = 'navigateTo',
  ADDITIONAL_DATA = 'additionalData',
}

export type PostMessage<
  A extends PostMessageType = PostMessageType,
  T extends {} = {}
> = {
  api: A | string;
  data: T;
  version: string;
  hash?: string;
};
