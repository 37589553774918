import { HttpStatusCode } from '../../core/api/HttpStatusCode';
import AxiosService from '../../core/api/AxiosService';
import { KnownCardId } from '../knowncards/KnownCard';
import { CreateCustomerCardForKnownCardRequest } from './CreateCustomerCardForKnownCardRequest';
import { CustomerCard } from './CustomerCard';
import { UpdateCustomerCardRequest } from './UpdateCustomerCardRequest';
import { CreateCustomerCardForUnknownCardRequest } from './CreateCustomerCardForUnknownCardRequest';

class CustomerCardRepository {
  public readonly url = '/customer-card';
  public readonly acceptHeadStatus = (status: number) =>
    status === HttpStatusCode.NOT_FOUND || status === HttpStatusCode.FOUND;

  async deleteCustomerCard(customerCardId: CustomerCard['id']) {
    return await AxiosService.delete<void>(`${this.url}/${customerCardId}`);
  }

  async createForKnownCard(request: CreateCustomerCardForKnownCardRequest) {
    return await AxiosService.post<void>(`${this.url}/known-card`, request);
  }

  async createForUnknownCard(request: CreateCustomerCardForUnknownCardRequest) {
    return await AxiosService.post<void>(`${this.url}/unknown-card`, request);
  }

  async userHasCustomerCards(): Promise<boolean> {
    let response = await AxiosService.head<void>(this.url, {
      validateStatus: this.acceptHeadStatus,
    });
    return response.status === HttpStatusCode.FOUND;
  }

  async userHasCustomerCardForKnownCardId(
    knownCardId: KnownCardId,
  ): Promise<boolean> {
    let response = await AxiosService.head<void>(this.url, {
      params: { knownCardId },
      validateStatus: this.acceptHeadStatus,
    });
    return response.status === HttpStatusCode.FOUND;
  }

  async getAll() {
    return await AxiosService.get<CustomerCard[]>(this.url);
  }

  async get(customerCardId: CustomerCard['id']) {
    return await AxiosService.get<CustomerCard>(
      `${this.url}/${customerCardId}`,
    );
  }

  async update(
    customerCardId: CustomerCard['id'],
    updateCustomerCardRequest: UpdateCustomerCardRequest,
  ) {
    return await AxiosService.put<void>(
      `${this.url}/${customerCardId}`,
      updateCustomerCardRequest,
    );
  }
}

export default new CustomerCardRepository();
