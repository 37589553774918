import {fromEvent, MonoTypeOperatorFunction, Observable, OperatorFunction} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {
  EventData,
  MessageJsonEvent,
  PublicEvent,
  PublicEventResponse,
  TokenEvent,
  Whitelist,
} from './interfaces/event.interfaces';

function filterTokenEvents(): OperatorFunction<EventData, TokenEvent> {
  return filter(
      (event): event is TokenEvent => 'api' in event && 'token' in event && !('response' in event));
}

function filterPublicEvents(): OperatorFunction<EventData, PublicEvent> {
  return filter(
      (event): event is PublicEvent =>
          'api' in event && !('token' in event) && !('response' in event));
}

const isPublicEventResponse = (event): event is PublicEventResponse =>
    'api' in event && !('token' in event) && 'response' in event;

function filterPublicEventResponses(): OperatorFunction<EventData, PublicEventResponse> {
  return filter(isPublicEventResponse);
}

/**
 * Remap init event to comply with sender update for Olympus
 * This remapping can be removed when 'apiLogonToken' is used instead of 'apiLogonResponse'
 */
function remapInitEventResponses(): OperatorFunction<EventData, EventData> {
  return map((event) => {
    if (isPublicEventResponse(event) && event.api === 'init') {
      const {api, version} = event;
      return {api, version, token: event.response.authorizationCode};
    }
    return event;
  });
}

function parsePayload(): OperatorFunction<MessageJsonEvent, EventData> {
  return map(event => {
    try {
      return JSON.parse(event.data);
    } catch (e) {
      throw new Error('Unable to parse event');
    }
  });
}

/**
 * Class used by external third parties to receive events from KBC
 */
export class PostmessageExternalReceiverReactive {
  /**
   * @since 201902
   * public events
   */
  public publicEvent$: Observable<PublicEvent>;
  /**
   * @since 201902
   * public events
   */
  public publicEventResponse$: Observable<PublicEventResponse>;
  /**
   * @since 201910
   * (jwt) token events
   */
  public tokenEvent$: Observable<TokenEvent>;

  public constructor(
      private readonly origins: (Whitelist|string)[] = [],
      private readonly windowInstance: Window = window) {
    origins.push(Whitelist.MOBILE_FET, Whitelist.MOBILE_ACC, Whitelist.MOBILE_PRO);
    this.initObservable();
  }

  private filterOrigin(): MonoTypeOperatorFunction<MessageJsonEvent> {
    return filter((event: MessageEvent) => this.origins.includes(event.origin));
  }

  private initObservable(): void {
    const messageObservable$ = fromEvent<Event>(this.windowInstance, 'message')
                                   .pipe(
                                       this.filterOrigin(),
                                       parsePayload(),
                                   );
    this.publicEvent$ = messageObservable$.pipe(
        remapInitEventResponses(),
        filterPublicEvents(),
    );
    this.publicEventResponse$ = messageObservable$.pipe(
        remapInitEventResponses(),
        filterPublicEventResponses(),
    );
    this.tokenEvent$ = messageObservable$.pipe(
        remapInitEventResponses(),
        filterTokenEvents(),
    );
  }
}
