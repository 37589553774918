import i18next, { StringMap } from 'i18next';
import { resources, SupportedLocale, TranslationKey } from '../locale';
import { parseMissingKeyHandler } from '../utils/parseMissingKeyHandler';
import { isValueOfEnum } from '../../core/util/isValueOfEnum';
import LogService from '../../core/logger/services/LogService';

class TranslateService {
  private defaultLanguage = SupportedLocale.nl;

  constructor() {
    i18next.init({
      resources,
      fallbackLng: this.defaultLanguage,
      parseMissingKeyHandler,
      initImmediate: true,
      returnEmptyString: false,
    });

    this.setSupportedLanguage(this.defaultLanguage);
  }

  t = (key: TranslationKey, options?: StringMap) => i18next.t(key, options);

  get language() {
    return i18next.language as SupportedLocale;
  }

  async setLanguage(language: string) {
    const lowerCasedLanguage = language.toLowerCase();
    if (isValueOfEnum(SupportedLocale, lowerCasedLanguage)) {
      LogService.log(
        '[TranslateService] Setting language to',
        lowerCasedLanguage,
      );
      await this.setSupportedLanguage(lowerCasedLanguage);
    }
  }

  private async setSupportedLanguage(language: SupportedLocale) {
    await i18next.changeLanguage(language);
  }
}

export default new TranslateService();
