import RouterService from '../../core/router/RouterService';
import TranslateService from './TranslateService';

class LanguageProviderService {
  async loadLanguageFromUrl() {
    const language = RouterService.getQueryParamFromUrl('language');

    if (language) {
      await TranslateService.setLanguage(language.toLowerCase());
    }
  }
}

export default new LanguageProviderService();
