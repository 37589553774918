import { PostMessage } from '../types/PostMessage/PostMessage';
import {
  BackNavigationPostMessage,
  NavigationType,
} from '../types/PostMessage/subtypes/NavigationPostMessage';
import { isNavigationPostMessage } from './isNavigationPostMessage';

export const isBackPostMessage = (
  event: PostMessage,
): event is BackNavigationPostMessage => {
  return (
    isNavigationPostMessage(event) && event.data.type === NavigationType.BACK
  );
};
