import { AxiosResponse } from 'axios';

const DataExtractionInterceptor = (response: AxiosResponse) => {
  if (response.config.method !== 'head') {
    return response.data;
  }
  return response;
};

export default DataExtractionInterceptor;
