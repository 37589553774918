import {
  PostMessageResponse,
  PostMessageResponseType,
} from '../types/PostMessageResponse/PostMessageResponse';
import { ScanPostMessageResponse } from '../types/PostMessageResponse/subtypes/ScanPostMessageResponse';

export const isScanPostMessageResponse = (
  response: PostMessageResponse,
): response is ScanPostMessageResponse => {
  return response.api === PostMessageResponseType.SCAN;
};
