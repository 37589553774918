import { PostMessage } from '../types/PostMessage/PostMessage';
import {
  AddKnownCardByNumberNavigationPostMessage,
  NavigationType,
} from '../types/PostMessage/subtypes/NavigationPostMessage';
import { isNavigationPostMessage } from './isNavigationPostMessage';

export const isAddKnownCardByNumberNavigationPostMessage = (
  event: PostMessage,
): event is AddKnownCardByNumberNavigationPostMessage => {
  return (
    isNavigationPostMessage(event) &&
    event.data.type === NavigationType.ADD_KNOWN_CARD_BY_NUMBER
  );
};
