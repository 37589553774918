import { AxiosRequestConfig } from 'axios';
import { GlobalHeaders } from '../../types/GlobalHeaders';

const GlobalHeaderInterceptor = (config: AxiosRequestConfig) => {
  config.headers = new GlobalHeaders(config.headers);

  return config;
};

export default GlobalHeaderInterceptor;
