import { PostMessageResponseContent } from './PostMessageResponseContent';

export enum PostMessageResponseType {
  SCAN = 'scanBarcode',
  PAGE_READY = 'pageReady',
  PAGE_LOADED = 'pageLoaded',
  KEEP_ALIVE = 'keepAlive',
}

export type PostMessageResponse<
  A extends PostMessageResponseType = PostMessageResponseType,
  T extends {} = {}
> = {
  api: A | string;
  response: PostMessageResponseContent<T>;
  version: string;
  hash?: string;
};
