import { generatePath, matchPath } from 'react-router-dom';
import PostMessageService from '../postMessage/services/PostMessageService';
import CustomerCardRepository from '../../model/customercard/CustomerCardRepository';
import { Routes } from './Routes';
import { history } from './history';

class RouterService {
  private readonly exitRoutes: Routes[] = [
    Routes.HOME,
    Routes.CUSTOMER_CARDS,
    Routes.KNOWN_CARD_OVERVIEW_WITH_EXIT,
  ];

  async initialNavigation() {
    let route = Routes.KNOWN_CARD_OVERVIEW_WITH_EXIT;
    if (
      !this.hasKnownCardOverviewNavigation() &&
      (await CustomerCardRepository.userHasCustomerCards())
    ) {
      route = Routes.CUSTOMER_CARDS;
    }
    history.replace(generatePath(route));
  }

  navigate(
    route: Routes,
    params?: { [paramName: string]: string | number | boolean | undefined },
  ) {
    history.push(generatePath(route, params));
  }

  replace(
    route: Routes,
    params?: { [paramName: string]: string | number | boolean | undefined },
  ) {
    history.replace(generatePath(route, params));
  }

  goBack() {
    let isCurrentRouteExitRoute = this.exitRoutes.some((exitRoute) =>
      this.isCurrentRoute(exitRoute),
    );
    if (isCurrentRouteExitRoute) {
      PostMessageService.send.close();
      return;
    }
    history.goBack();
  }

  hasKnownCardOverviewNavigation(): boolean {
    const additionalDataBase64 =
      this.getQueryParamFromUrl('kbcAdditionalData') || '';
    const additionalData = atob(additionalDataBase64);

    return additionalData === 'add';
  }

  private isCurrentRoute(path: string) {
    return !!matchPath(history.location.pathname, { path, exact: true });
  }

  getQueryParamFromUrl(key: string): string | null {
    const value = new URLSearchParams(history.location.search).get(key);

    if (typeof value === 'string') {
      return value;
    }

    return null;
  }
}

export default new RouterService();
