import TokenService from '../../auth/services/TokenService';
import SentryService from '../../sentry/services/SentryService';
import LogService from '../../logger/services/LogService';
import RouterService from '../../router/RouterService';
import Browser from '../../browser/Browser';
import PostMessageService from './PostMessageService';

class KBCAuthCodeService {
  private readonly logPrefix = '[KBCAuthCodeService]';

  async loginWithKBCAuthCode(authCode: string): Promise<void> {
    LogService.log(`${this.logPrefix} Start`);

    try {
      await TokenService.authorizeWithKBCAuthCode(authCode);

      await RouterService.initialNavigation();

      this.applyKBCMobileHistoryHack();

      LogService.log(`${this.logPrefix} Done`);
      PostMessageService.send.pageReady();
    } catch (e) {
      LogService.warn(`${this.logPrefix} Error occurred!`);

      SentryService.captureException(e);
      PostMessageService.send.close();
    }
  }

  // 2020-03-26: The addition of a random hash to the url is a hack, specifically requested by KBC,
  // as a workaround for the broken history stack on iOS.
  // Adding this workaround should allow the history.goBack() function to work as expected.
  // 2020-06-16: The history hack has been moved from a useEffect hook in KBCAuthCodeHandler,
  // after sending the pageLoaded event, to this service.
  // It will now be applied right before sending the pageReady event.
  private applyKBCMobileHistoryHack(): void {
    LogService.log(`${this.logPrefix} Applying #kbcMobileHistoryHack`);
    Browser.assignLocation(window.location.href + '#kbcMobileHistoryHack');
  }
}

export default new KBCAuthCodeService();
