import LogService from '../logger/services/LogService';

const kbcPostMessageOrigin = process.env.REACT_APP_KBC_POST_MESSAGE_ORIGIN;

export const ENV_NAME: string = process.env.REACT_APP_ENV_NAME!;
LogService.log('[Constants] Env name: ', ENV_NAME);

export const SENTRY_DSN_KEY: string | undefined =
  process.env.REACT_APP_SENTRY_KEY;
LogService.log('[Constants] Sentry DSN: ', SENTRY_DSN_KEY);

export const SENTRY_APP_RELEASE: string = process.env.REACT_APP_RELEASE!;
LogService.log('[Constants] Release: ', SENTRY_APP_RELEASE);

export const APP_VERSION: string = process.env.REACT_APP_VERSION!;
LogService.log('[Constants] Version: ', APP_VERSION);

export const BUILD_NUMBER: string =
  process.env.REACT_APP_BUILD_NUMBER || 'local';
LogService.log('[Constants] Build: ', BUILD_NUMBER);

export const KBC_POST_MESSAGE_ORIGIN:
  | [string]
  | undefined = kbcPostMessageOrigin ? [kbcPostMessageOrigin] : undefined;
LogService.log('[Constants] PostMessage origin: ', KBC_POST_MESSAGE_ORIGIN);

export const CUSTOMER_CARDS_BACKEND_URL: string = process.env
  .REACT_APP_CUSTOMER_CARDS_BACKEND_URL!;
LogService.log('[Constants] API: ', CUSTOMER_CARDS_BACKEND_URL);

export const CUSTOMER_CARDS_IMAGE_FROM_TEXT_BASE_URL: string = process.env
  .REACT_APP_CUSTOMER_CARDS_IMAGE_FROM_TEXT_BASE_URL!;
LogService.log(
  '[Constants] Image from text base url: ',
  CUSTOMER_CARDS_IMAGE_FROM_TEXT_BASE_URL,
);
