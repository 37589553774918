import { PostMessage, PostMessageType } from '../PostMessage';

export enum NavigationType {
  BACK = 'back',
  ADD_KNOWN_CARD_BY_NUMBER = 'add-known-card-by-number',
}

export type NavigationPostMessage<
  T extends NavigationType = NavigationType
> = PostMessage<PostMessageType.NAVIGATE_TO, { type: T }>;

export type BackNavigationPostMessage = NavigationPostMessage<
  NavigationType.BACK
>;

export type AddKnownCardByNumberNavigationPostMessage = NavigationPostMessage<
  NavigationType.ADD_KNOWN_CARD_BY_NUMBER
>;
