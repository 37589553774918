import React from 'react';
import { GlobalSpinner } from '../../shared/components/global-spinner/GlobalSpinner';
import LanguageProviderService from '../services/LanguageProviderService';
import LogService from '../../core/logger/services/LogService';

interface LanguageProviderProps {}

interface LanguageProviderState {
  loading: boolean;
}

export class LanguageProvider extends React.Component<
  LanguageProviderProps,
  LanguageProviderState
> {
  constructor(props: LanguageProviderProps) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    try {
      await LanguageProviderService.loadLanguageFromUrl();
    } catch (error) {
      LogService.error('[LanguageProvider] Setting language failed', error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) {
      return <GlobalSpinner />;
    }
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
