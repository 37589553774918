import React from 'react';
import { Router } from 'react-router-dom';
import { history } from './core/router/history';
import { LanguageProvider } from './i18n/components/LanguageProvider';
import { PostMessageProvider } from './core/postMessage/providers/PostMessageProvider/PostMessageProvider';
import { RouterOutlet } from './core/router/RouterOutlet';
import './core/polyfills/Array';

function App() {
  return (
    <Router history={history}>
      <LanguageProvider>
        <PostMessageProvider>
          <RouterOutlet />
        </PostMessageProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
