import { PostmessageExternalReceiver } from '@3ps/postmessage-api-external';
import {
  PostMessage,
  PostMessageType,
} from '../../types/PostMessage/PostMessage';
import { Subscription, Unsubscription } from '../../types/Subscription';
import { KBC_POST_MESSAGE_ORIGIN } from '../../../constants/constants';
import { isValueOfEnum } from '../../../util/isValueOfEnum';
import {
  PostMessageToken,
  PostMessageTokenType,
} from '../../types/PostMessageToken/PostMessageToken';
import {
  PostMessageResponse,
  PostMessageResponseType,
} from '../../types/PostMessageResponse/PostMessageResponse';
import SentryService from '../../../sentry/services/SentryService';
import LogService from '../../../logger/services/LogService';

class TypedPostMessageExternalReceiver {
  private receiver = new PostmessageExternalReceiver(KBC_POST_MESSAGE_ORIGIN);
  public logPrefix = '[PostMessageReceiver]';

  constructor() {
    window.addEventListener('message', this.messageDebugger);
  }

  private messageDebugger = ({ origin }: { origin: string }) => {
    const supportedOrigins = this.receiver['origins'];
    const isSupportedOrigin = supportedOrigins.includes(origin);

    if (!isSupportedOrigin) {
      const logMessage = `${this.logPrefix} Received message from unsupported origin ${origin}`;

      LogService.warn(logMessage);

      SentryService.captureMessage(logMessage, {
        origin,
        supportedOrigins,
      });
    } else {
      LogService.log(
        `${this.logPrefix} Received message from supported origin ${origin}`,
      );
    }
  };

  addMessageListener(callback: Subscription<PostMessage>): Unsubscription {
    return this.receiver.addPublicEventListener((api, data, version, hash) => {
      if (isValueOfEnum(PostMessageType, api)) {
        LogService.log(`${this.logPrefix} Received supported message ${api}`);

        callback({
          api,
          data,
          version,
          hash,
        });
      } else {
        const logMessage = `${this.logPrefix} Received unsupported message ${api}`;

        LogService.warn(logMessage);

        SentryService.captureMessage(logMessage, {
          api,
          data,
          version,
          hash,
        });
      }
    });
  }

  addTokenListener(callback: Subscription<PostMessageToken>): Unsubscription {
    return this.receiver.addTokenEventListener((api, token, version) => {
      if (isValueOfEnum(PostMessageTokenType, api)) {
        LogService.log(`${this.logPrefix} Received supported token ${api}`);

        callback({
          api,
          token: token.toString(),
          version,
        });
      } else {
        const logMessage = `${this.logPrefix} Received unsupported token ${api}`;

        LogService.warn(logMessage);

        SentryService.captureMessage(logMessage, {
          api,
          token,
          version,
        });
      }
    });
  }

  addResponseListener(
    callback: Subscription<PostMessageResponse>,
  ): Unsubscription {
    return this.receiver.addPublicEventResponseListener(
      (api, response, version, hash) => {
        if (isValueOfEnum(PostMessageResponseType, api)) {
          LogService.log(
            `${this.logPrefix} Received supported response ${api}`,
          );

          callback({
            api,
            response: {
              ...response,
              code: response.code.toUpperCase(),
            },
            version,
            hash,
          });
        } else {
          const logMessage = `${this.logPrefix} Received unsupported response ${api}`;

          LogService.warn(logMessage);

          SentryService.captureMessage(logMessage, {
            api,
            response,
            version,
            hash,
          });
        }
      },
    );
  }
}

export default new TypedPostMessageExternalReceiver();
