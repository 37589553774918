import React from 'react';
import styles from './GlobalSpinner.module.scss';

export class GlobalSpinner extends React.Component {
  render() {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner} />
      </div>
    );
  }
}
