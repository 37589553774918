/* eslint-disable no-console */
import { LOGGING_DISABLED } from '../../constants/logConstants';

type LogFnBuilder = (type: keyof typeof console) => (...args: any[]) => void;

class LogService {
  silent = LOGGING_DISABLED;

  private wrapConsoleMethod: LogFnBuilder = (type: keyof typeof console) => (
    ...args: any[]
  ) => {
    if (!this.silent) {
      console[type](...args);
    }
  };

  log = this.wrapConsoleMethod('log');
  info = this.wrapConsoleMethod('info');
  warn = this.wrapConsoleMethod('warn');
  error = this.wrapConsoleMethod('error');
}

export default new LogService();
