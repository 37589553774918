import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalSpinner } from '../../shared/components/global-spinner/GlobalSpinner';
import { NotFoundView } from '../../view/NotFoundView';
import { Routes } from './Routes';
import {
  lazyKnownCardOverview,
  lazyAddKnownCard,
  lazyCustomerCardOverview,
  lazyEditCardView,
  lazyHome,
  lazyAddUnknownCard,
} from './RouterComponents';

export class RouterOutlet extends React.Component {
  render() {
    return (
      <Suspense fallback={<GlobalSpinner />}>
        <Switch>
          <Route exact path={Routes.HOME} component={lazyHome} />
          <Route
            exact
            path={Routes.KNOWN_CARD_OVERVIEW}
            component={lazyKnownCardOverview}
          />
          <Route
            exact
            path={Routes.KNOWN_CARD_OVERVIEW_WITH_EXIT}
            component={lazyKnownCardOverview}
          />
          <Route exact path={Routes.EDIT_CARD} component={lazyEditCardView} />
          <Route
            exact
            path={Routes.CUSTOMER_CARDS}
            component={lazyCustomerCardOverview}
          />
          <Route
            exact
            path={Routes.ADD_KNOWN_CARD}
            component={lazyAddKnownCard}
          />
          <Route
            exact
            path={Routes.ADD_UNKNOWN_CARD}
            component={lazyAddUnknownCard}
          />
          <Route exact path={Routes.NOT_FOUND} component={NotFoundView} />
          <Redirect to={Routes.NOT_FOUND} />
        </Switch>
      </Suspense>
    );
  }
}
