import { CustomerCardToken } from '../types/CustomerCardToken';
import AxiosService from '../../api/AxiosService';

class TokenService {
  private _token?: string;

  origin = 'customer-cards-kbc-mobile-web-app';
  tokenUrl = `/user/token`;

  async authorizeWithKBCAuthCode(authCode: string) {
    const params = { oneTimeToken: authCode, origin: this.origin };

    const { token } = await AxiosService.post<CustomerCardToken>(
      this.tokenUrl,
      params,
    );
    this._token = token;
  }

  get token() {
    return this._token;
  }
}

export default new TokenService();
