import React from 'react';
import PostMessageService from '../../../../services/PostMessageService';
import KBCAuthCodeService from '../../../../services/KBCAuthCodeService';

export class KBCAuthCodeHandler extends React.Component {
  componentDidMount() {
    PostMessageService.onReceive.KBCAuthCode((authCode) =>
      KBCAuthCodeService.loginWithKBCAuthCode(authCode),
    );

    PostMessageService.send.pageLoaded();
  }

  render() {
    return null;
  }
}
