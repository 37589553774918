import React from 'react';
import PostMessageService from '../../../../services/PostMessageService';
import RouterService from '../../../../../router/RouterService';

export class BackHandler extends React.Component {
  componentDidMount() {
    PostMessageService.onReceive.back(() => RouterService.goBack());
  }

  render() {
    return null;
  }
}
