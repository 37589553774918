/* istanbul ignore file */

export enum Routes {
  HOME = '/',
  NOT_FOUND = '/not-found',
  KNOWN_CARD_OVERVIEW = '/known-cards',
  KNOWN_CARD_OVERVIEW_WITH_EXIT = '/known-cards-with-exit',
  EDIT_CARD = '/edit-card/:customerCardId',
  CUSTOMER_CARDS = '/customer-cards',
  ADD_KNOWN_CARD = '/add-known-card/:knownCardId/:knownCardName/:code/:format',
  ADD_UNKNOWN_CARD = '/add-unknown-card/:code/:format',
}
