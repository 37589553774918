import { Resource } from 'i18next';
import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';
import de from './de.json';

export enum SupportedLocale {
  'en' = 'en',
  'fr' = 'fr',
  'nl' = 'nl',
  'de' = 'de',
}

export type TranslationKey =
  | keyof typeof en
  | keyof typeof fr
  | keyof typeof nl
  | keyof typeof de;

export const resources: Resource = {
  en: { translation: en },
  fr: { translation: fr },
  nl: { translation: nl },
  de: { translation: de },
};
