/* istanbul ignore file */

import { lazy } from 'react';

export const lazyHome = lazy(() => import('../../view/Home'));
export const lazyKnownCardOverview = lazy(() =>
  import('../../view/known-card-overview/KnownCardOverview'),
);
export const lazyEditCardView = lazy(() =>
  import('../../view/edit-customer-card/EditCustomerCard'),
);
export const lazyCustomerCardOverview = lazy(() =>
  import('../../view/customer-card-overview/CustomerCardOverview'),
);
export const lazyAddKnownCard = lazy(() =>
  import('../../view/add-knowncard/AddKnownCard'),
);
export const lazyAddUnknownCard = lazy(() =>
  import('../../view/add-unknown-card/AddUnknownCard'),
);
