import React from 'react';
import PostMessageService from '../../../../services/PostMessageService';

export class KeepAliveHandler extends React.Component {
  keepAlive() {
    PostMessageService.send.keepAlive();
  }

  render() {
    return <div onClick={() => this.keepAlive()}>{this.props.children}</div>;
  }
}
