import { Subscription, Unsubscription } from '../types/Subscription';
import { isBackPostMessage } from '../utils/isBackPostMessage';
import { isScanPostMessageResponse } from '../utils/isScanPostMessageResponse';
import { ScanPostMessageResponse } from '../types/PostMessageResponse/subtypes/ScanPostMessageResponse';
import { isAddKnownCardByNumberNavigationPostMessage } from '../utils/isAddKnownCardByNumberNavigationPostMessage';
import TypedPostMessageExternalReceiver from './utils/TypedPostMessageExternalReceiver';

class PostMessageReceiverService {
  public back = (onBackMessageReceived: Subscription): Unsubscription => {
    return TypedPostMessageExternalReceiver.addMessageListener(
      (postMessage) => {
        if (isBackPostMessage(postMessage)) {
          onBackMessageReceived();
        }
      },
    );
  };

  public scan = (
    onScanResponseReceived: Subscription<ScanPostMessageResponse>,
  ): Unsubscription => {
    return TypedPostMessageExternalReceiver.addResponseListener(
      (postMessageResponse) => {
        if (isScanPostMessageResponse(postMessageResponse)) {
          onScanResponseReceived(postMessageResponse);
        }
      },
    );
  };

  public KBCAuthCode = (
    onKBCAuthCodeReceived: Subscription<string>,
  ): Unsubscription => {
    return TypedPostMessageExternalReceiver.addTokenListener(({ token }) => {
      onKBCAuthCodeReceived(token);
    });
  };

  public addKnownCardByNumberNavigation = (
    onAddKnownCardByNumberNavigationReceived: Subscription,
  ): Unsubscription => {
    return TypedPostMessageExternalReceiver.addMessageListener(
      (postMessage) => {
        if (isAddKnownCardByNumberNavigationPostMessage(postMessage)) {
          onAddKnownCardByNumberNavigationReceived();
        }
      },
    );
  };
}

export default new PostMessageReceiverService();
