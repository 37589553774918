import axios, { AxiosInstance } from 'axios';
import { CUSTOMER_CARDS_BACKEND_URL } from '../constants/constants';
import GlobalHeaderInterceptor from './interceptor/request/GlobalHeaderInterceptor';
import { AutomaticDataExtractionAxiosInstance } from './types/AutomaticDataExtractionAxiosInstance';
import DataExtractionInterceptor from './interceptor/response/DataExtractionInterceptor';

const AxiosService = axios.create({
  baseURL: CUSTOMER_CARDS_BACKEND_URL,
});

/*
 This function adds a response interceptor which automatically unwraps the AxiosResponse and
 returns the 'data' property.
 The instance is then marked with a custom type which modifies the request-methods-types to return
 the data-type instead of an AxiosResponse.
*/
function convertToAutomaticDataExtractionAxiosInstance(
  axiosInstance: AxiosInstance,
): AutomaticDataExtractionAxiosInstance {
  axiosInstance.interceptors.request.use(GlobalHeaderInterceptor);
  axiosInstance.interceptors.response.use(DataExtractionInterceptor);

  return axiosInstance;
}

export default convertToAutomaticDataExtractionAxiosInstance(AxiosService);
