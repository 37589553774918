import {version} from '../../package.json';

import {CameraOptions} from './interfaces/CameraOptions.interfaces';
import {PublicEvent, TokenEvent} from './interfaces/event.interfaces';
import {AdditionalOptions} from './interfaces/start-authentication.interface';

/**
 * scanBarcode allowed types
 */
export type BarCodeTypes = 'QR_CODE'|'ALL';

export type RefreshType = 'customerCards';

export interface CloseEventData {
  force: boolean
}

/**
 * showMessage allowed types
 */
type MessageTypes = 'success' | 'warning' | 'error' | 'info';

/**
 * Class used by external third parties to send events to KBC
 */
export class PostmessageExternalSender {
  private readonly version = version;
  // reference to https://gist.github.com/bgrins/6194623
  // @ts-ignore
  private readonly regex =
      /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)$/i;

  public constructor(private readonly windowInstance: Window = window) {
  }

  /**
   * @since 201902
   */
  public close(force: boolean = false): void {
    const request: PublicEvent = {
      api: 'close',
      data: {force},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202101
   * Use closeWebview for a custom flow
   * For landing on the overview screen use close instead
   */
  public closeWebview(viewName: string): void {
    const request: PublicEvent = {
      api: 'closeWebview',
      data: {viewName},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   */
  public getLocation(): void {
    const request: PublicEvent = {
      api: 'getLocation',
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   * @param url
   */
  public openUrl(url: string): void {
    const request: PublicEvent = {
      api: 'openUrl',
      data: {url},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202001
   * @param url
   */
  public openUrlEmbedded(url: string): void {
    const request: PublicEvent = {
      api: 'openUrlEmbedded',
      data: {url},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   */
  public pageLoaded(): void {
    const request: PublicEvent = {
      api: 'pageLoaded',
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   */
  public pageReady(): void {
    const request: PublicEvent = {
      api: 'pageReady',
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   */
  public keepAlive(): void {
    const request: PublicEvent = {
      api: 'keepAlive',
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * @param type
   */
  public scanBarcode(type?: BarCodeTypes): void {
    const request: PublicEvent = {
      api: 'scanBarcode',
      data: type ? {type} : {type: 'ALL'},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201902
   * @deprecated
   * @param payment
   */
  public startPayment(payment: Object, hash: string): void {
    const request: PublicEvent = {
      api: 'startPayment',
      data: payment,
      hash,
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * @param jwt
   */
  public startPaymentToken(jwt: string): void {
    const request: TokenEvent = {
      api: 'startPaymentToken',
      token: jwt,
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * @param cameraOptions contains options for the camera, see cordova camera plugin
   */
  public getPicture(cameraOptions?: CameraOptions): void {
    const request: PublicEvent = {
      api: 'getPicture',
      data: {cameraOptions},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * @param token the secure data that needs to be stored at KBC
   */
  public storeSecureData(token: string): void {
    const request: PublicEvent = {
      api: 'storeSecureData',
      data: {secureData: token},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * Receive the stored secure data from KBC
   */
  public getSecureData(): void {
    const request: PublicEvent = {
      api: 'getSecureData',
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @param title: Title of the message
   * @param text: Text shared with the image
   * @param file:
   * mimetype (mediatype meta data: image/png, application/pdf,...)
   * base64; (fixed extension)
   * base64 data (the actual file)
   */
  public socialSharing(title: string, text: string, file?: string): boolean {
    if (file !== undefined && !this.validDataUrl(file)) {
      return false;
    }
    const request: PublicEvent = {
      api: 'socialSharing',
      data: {
        title,
        text,
        file,
      },
      version: this.version,
    };
    this.sendEvent(request);

    return true;
  }

  /**
   * @since 201910
   * @deprecated
   * @param document: version < 2005: base64 encoded pdf document - version >= 2005: path to
   *     document on BlueCap service
   */
  public openPdf(document: string): void {
    const request: PublicEvent = {
      api: 'openPdf',
      data: document,
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 201910
   * Show a message on top of the screen (optional customisation for message type and text content)
   */
  public showMessage(type?: MessageTypes, text?: string): void {
    const request: PublicEvent = {
      api: 'showMessage',
      data: {
        type,
        text,
      },
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202002
   * Assign a new url to the parent window.
   * Only available for trusted third party origins where this functionality is specified in the
   * scope.
   * @param destination: url to navigate to.
   */
  public navigateTop(destination: string): void {
    const request: PublicEvent = {
      api: 'navigateTop',
      data: {destination},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202002
   * Start an authentication flow
   * View readme for more information
   */
  public startAuthentication(options?: AdditionalOptions): void {
    const request: PublicEvent = {
      api: 'startAuthentication',
      version: this.version,
      data: options,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202006
   * Make a phone call
   * @param phoneNumber: number to call
   */
  public makePhoneCall(phoneNumber: string) {
    const request: PublicEvent = {
      api: 'makePhoneCall',
      data: {phoneNumber},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202006
   * Send an email
   * @param email: recipient email
   */
  public sendEmail(email: string) {
    const request: PublicEvent = {
      api: 'sendEmail',
      data: {email},
      version: this.version,
    };
    this.sendEvent(request);
  }

  /**
   * @since 202101
   * Request a refresh of data
   * @param type: type of the refresh
   */
  public refresh(type: RefreshType) {
    const request: PublicEvent = {
      api: 'refresh',
      data: {type},
      version: this.version,
    };
    this.sendEvent(request);
  }

  private sendEvent(request: PublicEvent | TokenEvent): void {
    this.windowInstance.parent.postMessage(JSON.stringify(request), '*');
  }

  public validDataUrl(dataUrl): boolean {
    const regEx = new RegExp(this.regex);
    return regEx.test(dataUrl);
  }
}
